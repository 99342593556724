<template>
  <div>
    <div id="start">
      <landing-header @about-us-selected="goToServices" :landingHeader="landingHeader" :landingDescription="landingDescription"
                    :landingImg="landingImg"></landing-header>

    <text-icon :srcArray="textIconData"></text-icon>
    </div>
    <div id="services">
      <primary-text :primaryTextHeader="primaryTextHeader" :primaryTextDescription="primaryTextDescription" ></primary-text>
    </div>
    <hover-cards @card-clicked="this.hoverCardClicked" :srcArray="hoverCardData" :isCarousel=true></hover-cards>
    <div class="twoBtnContainer">
      <div class="headline">Noch Fragen?</div>
      <two-button @btn-one-clicked="goToContact" :contentDescription="contentDescription"
                  :buttonLabelOne="buttonLabelOne"></two-button>
    </div>
    <div class="sliderComponentContainer">
      <slider-component :slider-content="sliderContent"></slider-component>
    </div>
    <div class="aboutContainer" id="company">
      <about-component :about-content="aboutContent"></about-component>
    </div>
    <div class="customerReviewsContainer">
      <customer-reviews :srcArray="customerReviewsData"></customer-reviews>
    </div>
    <div id="contact">
      <contact></contact>
    </div>
  </div>
</template>

<script>

import "@/components/lit-element/primary-text/primary-text";
import "@/components/lit-element/hover-cards/hover-cards";
import "@/components/lit-element/landing-header/landing-header";
import "@/components/lit-element/customer-reviews/customer-reviews";
import {LandingHeaderStrings} from "/src/components/lit-element/landing-header/landing-header-strings";
import LandingImage from "/src/assets/images/sliderImgs/imgAutomotive.jpg"

import "/src/components/lit-element/text-icon/text-icon"
import engineering from "/src/assets/icons/engineering_black_24dp.svg"
import iconTechnologies from "/src/assets/icons/architecture_black_24dp.svg"
import iconClock from "/src/assets/icons/history_toggle_off_black_24dp.svg"
import iconThumbUp from "/src/assets/icons/thumb_up_black_24dp.svg"
import {PrimaryTextStrings} from "@/components/lit-element/primary-text/primary-text-strings";

import "/src/components/lit-element/two-button/two-button"
import {TwoButtonStrings} from "@/components/lit-element/two-button/two-button-strings";

//row-elements

import installationImg from "/src/assets/images/service/installation.png";
import wartungImg from "/src/assets/images/service/wartung.png"
import reparaturImg from "/src/assets/images/service/reparatur.png"
import produktionsbegleitungImg from "/src/assets/images/service/produktionsbegleitung.png"
import prozessberatungImg from "/src/assets/images/service/prozessberatung.png"

import imgAutomotive from "/src/assets/images/sliderImgs/imgAutomotive.jpg";
import imgMaschinenbau from "/src/assets/images/sliderImgs/imgMaschinenbau.jpg";
import imgWerkzeug from "/src/assets/images/sliderImgs/imgWerkzeug.jpg";

import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";
import Contact from "@/views/Contact";
import SliderComponent from "@/components/vue/slider-component/slider-component";
import AboutComponent from "@/components/vue/about-component/about-component";
import buderusLogo from "@/assets/logos/buderuslogo.png";
import dvsLogo from "@/assets/logos/dvslogo.png";
import rhmLogo from "@/assets/logos/rhmlogo.png";


export default {
  name: "HomePage",
  components: {AboutComponent, SliderComponent, Contact},
  mixins: [SweetAlerts],
  data(){
    return{
      landingHeader: LandingHeaderStrings.sliderHeaderText1(),
      landingDescription: LandingHeaderStrings.sliderText1(),
      landingImg: LandingImage,

      textIconData: [
          {
            textIconImg:engineering,
            textIconHeader: "100%",
            textIconDescription: "Fachwissen",
          },
          {
            textIconImg:iconTechnologies,
            textIconHeader: "über 5",
            textIconDescription: "Technologien",
          },
          {
            textIconImg: iconClock,
            textIconHeader: "15 Jahre",
            textIconDescription: "Erfahrung",
          },
          {
            textIconImg: iconThumbUp,
            textIconHeader: "100%",
            textIconDescription: "Einsatz",
          }
      ],

      primaryTextHeader: PrimaryTextStrings.primaryTextHeader(),
      primaryTextDescription: PrimaryTextStrings.primaryTextDescription(),

      hoverCardData: [
        {
          imgSrc:installationImg,
          imgAlt: "Alt text",
          headerText: "Inbetriebnahmen",
          descriptionText: "Mit einer hohen Reisebereitschaft, stehen wir kompetent zur Seite.",
        },
        {
          imgSrc:wartungImg,
          imgAlt: "Alt text",
          headerText: "Montage",
          descriptionText: "Ihr zuverlässiger Partner für die Maschinen Montage.",
        },
        {
          imgSrc:produktionsbegleitungImg,
          imgAlt: "Alt text",
          headerText: "Produktionsbegleitung",
          descriptionText: "Spezialwissen bei der Handhabung, Bedienung und Wartung.",
        },
        {
          imgSrc:reparaturImg,
          imgAlt: "Alt text",
          headerText: "Reparatur",
          descriptionText: "Fachwissen gepaart mit schneller Einsatzverfügbarkeit.",
        },
        {
          imgSrc:prozessberatungImg,
          imgAlt: "Alt text",
          headerText: "Umbau",
          descriptionText: "Kosteneffiziente und nachhaltige Lösungen für den Umbau.",
        },
        {
          imgSrc:wartungImg,
          imgAlt: "Alt text",
          headerText: "Wartung",
          descriptionText: "Damit Ihre Maschinen und Anlagensysteme produktiv laufen.",
        },
      ],
      aboutContent:
        {
          header: "Expertise und Technologie",
          subHeader: "Für den Maschinen- und Anlagenbau",
          mainText: "Wir sind ein erfahrener Anbieter für den Maschinen- und Anlagenbau und bieten umfassende Service-Dienstleistungen an. Unser Ziel ist es, Sie bei der Herstellung Ihrer Maschinen zu unterstützen, Ihre Produktivität zu steigern, Stillstandzeiten zu minimieren und die Lebensdauer Ihrer Maschinen zu maximieren. Unsere Mitarbeiter sind erfahrene Techniker, Ingenieure und Projektkoordinatoren, die bereits bei zahlreichen Maschinenbau-Unternehmen tätig waren. Wir legen Wert auf hohe Qualitätsstandards, Zuverlässigkeit, Flexibilität und eine partnerschaftliche Zusammenarbeit.",
          imgSrc: imgAutomotive,
          imgAlt:"image industry automotive",
          listItemOne:"Produktivität steigern",
          listItemTwo:"Stillstandszeiten minimieren",
          listItemThree:"Prozesse optimieren",
        },
      sliderContent:[
        {
          header: "Industrien",
          subHeader: "Automotive",
          mainText: "Im Bereich Automotive sind wir insbesondere in der Montagetechnik und der zerspanenden Fertigung tätig. Auch unterschiedliche Verkettungen von Gesamtanlagen werden von uns instand gehalten.\n" +
              "\n" +
              "Seien es Fertigungsprozesse rund um das Drehen, Fräsen und Schleifen von Bauteilen, oder die Inbetriebnahme von Automationsanlagen zur Herstellung von Statoren und Batterien – wir können auf fundiertes Wissen zurückgreifen.",
          imgSrc: imgAutomotive,
          imgAlt:"image industry automotive",
          isSelected:true
        },
        {
          header: "Industrien",
          subHeader: "Maschinenbau",
          mainText: "Auch bei der Wartung und Instandhaltung Ihrer Anlagen für die Herstellung besonders stabiler Teile im Bereich Maschinenbau sind wir ihr kompetenter Ansprechpartner.\n" +
              "\n" +
              "Die Aufstellung, Wartung und Reparatur von Dreh-, Schleif- und Verzahn-Maschinen sind hierbei tagtägliche Aufgabenfelder, bei denen wir  Sie gerne unterstützen.",
          imgSrc: imgMaschinenbau,
          imgAlt:"image industry machine",
          isSelected:false
        },
        {
          header: "Industrien",
          subHeader: "Werkzeug- und Formenbau",
          mainText: "Der Service an Großmaschinen für den Werkzeug- und Formenbau zählt ebenso zu unserem Produktportfolio. Insbesondere Instandhaltungsaufgaben werden von uns in diesem Bereich wahrgenommen.",
          imgSrc: imgWerkzeug,
          imgAlt:"image industry machine",
          isSelected:false
        },
      ],
      customerReviewsData: [
        {
          imgSrc: buderusLogo,
          imgAlt: "Logo Buderus",
        },
        {
          imgSrc: dvsLogo,
          imgAlt: "Logo DVS",
        },
        {
          imgSrc: rhmLogo,
          imgAlt: "Logo RHM Service Gmbh",
        },
      ],

      contentDescription: TwoButtonStrings.contentDescription(),
      buttonLabelOne: TwoButtonStrings.buttonLabelOne(),

    }
    },
    methods:{
      goToServices(){
        window.scrollTo(
            0,
            document.getElementById("services").getBoundingClientRect().y +
            window.scrollY -
            40
        );
      },
      goToContact(){
        window.scrollTo(
            0,
            document.getElementById("contact").getBoundingClientRect().y +
            window.scrollY -
            40
        );
      },
    }
}
</script>

<style scoped>
  .sliderComponentContainer{
    padding: 100px 20px;
  }
  .twoBtnContainer{
    padding: 100px 20px 100px 20px; background-color: #2c3e50; color: white;
  }

  .customerReviewsContainer{
    background-color: #fff;
    padding-bottom: 20px;
    margin: auto;
  }

  .headline{
    margin-bottom: 24px; font-size: 40px;
    font-weight: 500;
  }

  .aboutContainer{
    font-size: 40px;
    background-color: var(--bright);
    padding: 100px 20px;
  }
</style>
