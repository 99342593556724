import {html} from "lit";
import {LitElement} from "lit";
import {ButtonStyles} from "./button-styles";

export class Button extends LitElement{

    constructor() {
        super();

        this.btnText = ""
    }

    static get styles(){
        return ButtonStyles
    }

    buttonClicked(){
        const customEvent = new CustomEvent("button-clicked",{})
        this.dispatchEvent(customEvent);
    }

    render() {
        return html`
            <button class="btn" @click="${()=> this.buttonClicked()}">${this.btnText}</button>
        `
    }
}

customElements.define("single-button", Button)