<template>
  <div class="container">
    <div class="mobile-header">
      <div @click="this.navigate('/')" style="color:white;">
        <span>DMD</span>
      </div>
      <div @click="this.isOpen = !this.isOpen" class="menu-button">
        <span v-if="!isOpen" >
          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
        </span>
        <span v-if="isOpen">
          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/></svg>
        </span>
      </div>
      <transition name="nested" :duration="550">
        <div v-if="isOpen" class="nav-content-container outer">
          <div @click="this.isOpen = false" class="inner flex">
            <router-link class="main-tab" to="/#start">Start</router-link>
            <router-link class="main-tab" to="/#services">Dienstleistungen</router-link>
            <router-link class="main-tab" to="/#component">Unternehmen</router-link>
            <router-link class="main-tab" to="/#contact">Kontakt</router-link>
          </div>
          <SmallFooter/>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
import {NavigationBarLocales} from "@/locales/navigation-bar/navigation-bar-locales";
import SmallFooter from "@/components/vue/footer-bar/SmallFooter";
export default {
  name: "NewMobileNavBar",
  components:{SmallFooter },
  data(){
    return{
      isOpen: false,

      home: NavigationBarLocales.home(),
      start: NavigationBarLocales.start(),
      contact: NavigationBarLocales.contact(),
      partner:NavigationBarLocales.partner(),
      career: NavigationBarLocales.career(),
      team: NavigationBarLocales.team(),
      technologies: NavigationBarLocales.technologies(),
      fraesen: NavigationBarLocales.fraesen(),
      komplettbearbeitung: NavigationBarLocales.komplettbearbeitung(),
      verzahnen: NavigationBarLocales.verzahnen(),
      superfinishen: NavigationBarLocales.superfinishen(),
      drehen: NavigationBarLocales.drehen(),
      honen: NavigationBarLocales.honen(),
      schleifen: NavigationBarLocales.schleifen(),
      richten: NavigationBarLocales.richten(),
      automatisieren: NavigationBarLocales.automatisieren(),
      montieren: NavigationBarLocales.montieren(),
      reinigen: NavigationBarLocales.reinigen(),
      consulting: NavigationBarLocales.consulting(),
      news: NavigationBarLocales.news(),
    }
  },
  methods:{
    // navigate to a view, by providing the path.
    // each path needs to be registered inside the router
    // component first.
    navigate(extension){
      this.isOpen = false;

      this.$router.push({
        path: extension,
      })
    },
  }
}

</script>

<style scoped>

.flex{
  display: flex;
  flex-direction: column;
}

.nav-content-container .sub-nav span{
  padding: 20px;
  font-size: 16px;
}

.nav-content-container .main-tab{
  padding: 20px;
  font-size: 18px;
  border-bottom: 1px solid var(--secondary-color);
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0;
}

/* delay enter of nested element for staggered effect */
.nested-enter-active .inner {
  transition-delay: 0.25s;
}
  .mobile-header{
    height: 50px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--darkgrey);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid white;
    z-index: 100;
  }

  body{
    overflow: hidden;
  }

  .nav-content-container{
    position: absolute;
    left: 0;
    top: 50px;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    text-align: left;
    overflow: auto;
  }

  .menu-button span{
    display: flex;
  }

  a{
    color: black;
    text-decoration: none;
  }

</style>
