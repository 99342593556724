

export class HttpsRequests {

    static postRequest(identifier, payload) {
        return new Promise((resolve) => {

            const requestOptions = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({identifier: identifier, payload: payload})
            };

            fetch("https://12ckhgvl52.execute-api.eu-central-1.amazonaws.com/prod", requestOptions)
                .then(response => resolve(response))
        });
    }
}
