<template>
  <div id="app">
    <div class="desktop">
      <NavBar></NavBar>
    </div>
    <div class="mobile">
      <NewMobileNavBar></NewMobileNavBar>
    </div>
    <router-view>
    </router-view>
    <cookie-banner v-if="!isAccepted"></cookie-banner>
    <small-footer></small-footer>
  </div>
</template>

<script>

import SmallFooter from "@/components/vue/footer-bar/SmallFooter";
import CookieBanner from "@/components/lit-element/cookie-banner/cookieBanner";
import NavBar from "@/components/vue/NavBar";
import NewMobileNavBar from "@/components/vue/navigation-bar/NewMobileNavBar";


export default {
  name: 'App',
  components: {NewMobileNavBar, NavBar, CookieBanner, SmallFooter},
  methods:{
    navigate(route){
      this.$router.push({
        "path": route
      })
    }
  },
  computed:{
    isAccepted(){
      return localStorage.getItem("cookieBannerAccepted");
    }
  },
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'de')
  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap');

html {
  scroll-behavior: smooth;
}

body{
  margin:0;
  box-sizing: border-box;
  background-color: #f1f1f1;
}

#app {
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
}

:root {
  --primary-color: #005b9d;
  --secondary-color: #bab6b5;
  --bright: #ffffff;
  --lightgrey: #e0e1e2;
  --grey: #616263;
  --darkgrey: #1d1d1d;;

  --primary-font: "Poppins", sans-serif;
  --secondary-font: 'Roboto', sans-serif;
}

.desktop {
  display: block;
}

.mobile{
  display: none;
}

@media only screen and (max-width: 720px) {
  .desktop {
    display: none;
  }

  .mobile{
    display: block;
  }

}
</style>
