import {css} from "lit-element";

export const TextIconStyles = css`
  
  .contentWrapper{
    display: flex;
    justify-content: space-evenly;
    max-width: 1100px;
    height: fit-content;
    flex-wrap: wrap;
    margin: auto;
  }

  .rowOne{
    width: 80%;
    display: flex;
    justify-content: space-around;
  }

  .itemOuterDiv{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;

  }

  .itemHeader{
    font-weight: 500;
    color: black;
  }
  
  .itemImage{
    height: 100%;
    width: 40px;
    float: left;
    padding-right: 5px;
    margin-right: 10px;
    border-right: 3px solid var(--primary-color);
  }

  .itemTextDiv{
    width: fit-content;
    text-align: left;
    font-size: 16px;
    color: grey;
  }

  @media only screen and (max-width: 1050px) {
    .contentWrapper{
      display: grid;
      grid-template-columns: auto auto;
    }
    
    .itemOuterDiv{
      margin-right: 15px;
    }
    
    .itemOuterDiv:last-child{
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 720px) {
    
  }

  @media only screen and (max-width: 480px) {
    .contentWrapper{
      grid-template-columns: auto;
    }
  }
    
`