
export class LandingHeaderStrings{

    static sliderText1(){
        return "Inbetriebnahmen, Wartungen, Reparaturen und allgemeine Instandhaltung - wir sind Ihr Partner, wenn es um " +
            "Werkzeugmaschinen & Montageanlagen geht. Von der Produktionsbegleitung bis zur Behebung der komplexesten Problemfelder stehen wir für Kompetenz, Innovation" +
            " und Zuverlässigkeit.";
    }

    static sliderHeaderText1(){
        return "Einfach guter Service!";
    }

    static sliderShowMore(){
        return "Erfahren Sie mehr";
    }

}