
export class TwoButtonStrings{

    static contentDescription(){
        return "Hier können Sie direkt eine Kontaktanfrage stellen. Wir freuen uns auf Sie!"
    }

    static buttonLabelOne(){
        return "Kontaktanfrage stellen";
    }

}