import {css} from "lit-element";

export const ButtonStyles = css`
  .btn{
    width: 200px;
    height: 50px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    border: 3px solid var(--primary-color);
    font-weight: bold;
    opacity: 1;
    transition: opacity 0.2s;
    font-size: 16px;
  }

  .btn:hover{
    opacity: 0.5;
    transition: opacity 0.2s;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    .btn{
      width: 100%;
    }
  }
    
`