<template>
  <div class="outerContainer">
    <div class="contentContainer">
      <div>
        <img :src="aboutContent.imgSrc" :alt="aboutContent.imgAlt">
      </div>
      <div class="contentWrapper">
        <div class="header">{{aboutContent.header}}</div>
        <div class="subHeader">{{aboutContent.subHeader}}</div>
        <div class="mainText">{{aboutContent.mainText}}</div>
        <ul style="font-size: 22px">
          <li>{{aboutContent.listItemOne}}</li>
          <li>{{aboutContent.listItemTwo}}</li>
          <li>{{aboutContent.listItemThree}}</li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: "about-component",
  props:{
    aboutContent:{
      type: Array,
      required: true,
    }
  },

  data(){
    return{

    }
  },

  methods:{
  },

}
</script>

<style scoped>
  .outerContainer{
    max-width: 1100px;
    margin: auto;
    position: relative;
  }

  .contentContainer{
    display: flex;
    justify-content: space-between;
    gap: 80px;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    opacity: 1;
  }

  .contentWrapper{
    width: 60%;
    text-align: left;
  }

  img{
    max-width: 400px;
    width: 100%;
    object-fit: contain;
    border-radius: 2px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  }

  blockquote{
    margin: 0;
  }

  .header{
    font-weight: 400;
    font-size: 25px;
    margin: 0;
    line-height: 1.31;
    color: black;
  }

  .subHeader{
    text-align: left;
    width: 100%;
    max-width: 600px;
    margin: 0 0 20px;
    font-size: 3rem;
    font-weight: 500;
    color: black;
  }

  .mainText{
    font-size: 16px;
    line-height: 28px;
    color: black;
  }

  @media only screen and (max-width: 1100px) {
    .contentContainer{
      flex-direction: column;
      position: revert;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 2px;
      box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
    }

    .contentWrapper{
      width: 100%;
    }

    .outerContainer{
      position: relative;
    }
  }
</style>