export class ContactFormStrings{
    static lastName(){
        return"Name"
    }

    static firstName(){
        return"Vorname"
    }

    static email(){
        return"E-Mail"
    }

    static message(){
        return"Nachricht"
    }

    static data(){
        return "Ich habe die Hinweise zum Datenschutz gelesen und akzeptiere diese ";
    }

    static send(){
        return"Absenden"
    }


}