<template>
  <div class="outerDivTwo">
    <div class="flex-container">
      <div class="address-container">
        <div class="margin-bottom address-wrapper">
          <div class="adressHeader">Projektanfragen</div>
          <div style="display: flex; flex-direction: column;">
            <span class="adressText">Sie kennen Ihren Ansprechpartner noch nicht?</span>
            <a class="mail" href="mailto:danijel1985@web.de">danijel1985@web.de</a>
          </div>
        </div>
        <div class="address-wrapper">
          <div class="adressHeader">Für alle anderen Anliegen</div>
          <span class="adressText">Dosen Maschinenbau Dienstleistung</span>
          <span class="adressText">Goethestrasse, 23</span>
          <span class="adressText">D-73770 Denkendorf</span>
          <a class="tel" href="tel:+491234567890">+49 173 411 0475</a>
          <a class="mail" href="mailto:danijel1985@web.de">danijel1985@web.de</a>
        </div>
        <div style="margin-top: 20px;">
          <img :src="dmdLogo" alt="This image shows the logo of the company DMD">
        </div>
      </div>
    </div>
    <div class="rowThree" style="color: black">
      <div class="contentDivOne">
        <a @click="this.goToRoute('/data-privacy')" class="text small">Datenschutz</a>
        <a @click="this.goToRoute('/legal-notice')" class="text small">Impressum</a>
        <a class="text small" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&hl=de">Cookie-Einstellungen</a>
      </div>
    </div>
  </div>
</template>

<script>

import SweetAlerts from "../../../mixins/sweet-alerts/SweetAlerts";

// images
import dmdLogo from "/src/assets/logos/logo-dosen-maschinenbau.png";

export default {
  name: "FooterBar",
  mixins: [SweetAlerts],
  data(){
    return{
      email: "",
      dmdLogo: dmdLogo,
    }
  },
  methods:{

    goToRoute(route){
      this.$router.push({
        "path": route
      })
    },

    isEmailValid(email){
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

  }
}
</script>

<style scoped>

.rowThree {
  padding: 20px 0;
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid black ;
}

a{
  text-decoration: none;
  color: var(--primary-color);
}

a:hover{
  text-decoration: underline;
  cursor: pointer;
}

.icon-label-wrapper:hover span{
  color: var(--primary-color);
}

.icon-label-wrapper:hover svg path{
  fill: var(--primary-color);
}

.text {
  color: black;
  text-decoration: none;
  display: block;
}


.contentDivOne {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contentDivOne .small{
  margin-right: 20px;
}

.contentDivOne .small:last-child{
  margin-right: 0;
}

.iconDiv {
  margin-top: 20px;
}

.icon-label-wrapper{
  display: flex;
  align-items: center;
}

.iconLabel{
  color: rgba(245, 245, 245, 0.8);;
  text-decoration: none;

}

.mail-icon{
  width:48px;
  height:48px;
}

.outerDivTwo{
  background-color: var(--bright);
}


/*
styling for contact section
 */

.margin-bottom{
  margin-bottom: 10px;
}

.address-container{
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.address-container img {
  max-width: 100px;
}

.flex-container{
  justify-content: space-between;
  max-width: 1100px;
  margin: auto;
  padding: 100px 20px;
  align-items: center;
}

.address-wrapper{
  display: flex;
  flex-direction: column;
}

.img-container{
  max-width: 430px;
}

.adressHeader{
  font-size: larger;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.img-container{
  margin-left: 20px;
}

@media only screen and (max-width: 720px){
  .flex-container{
    flex-direction: column;
  }

  .img-container{
    margin-top: 20px;
    max-width: none;
    margin-left: 0;
  }

  .address-container{
    margin-bottom: 80px;
  }
}

/*
end styling contact section
 */

@media only screen and (max-width: 1050px) {
  .address-container{
    display: block;
  }
  .flex-container{
    padding: 20px 20px 40px 20px;
  }
}

@media only screen and (max-width: 720px) {
  .contentWrapperTwo{
    display: flex;
    flex-direction: column;
  }

  .contentRowOne{
    display: block;
  }

  .newsletter{
    margin-bottom: 10px;
  }

  .emailField {
    width: 100%;
    margin-bottom: 20px;
  }

  .newsletterWrapper {
    width:auto;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (max-width: 455px) {
  .contentDivOne {
    display: block;
    justify-content: left;
    align-items: revert;
  }

  .contentDivOne .small{
    text-align: left;
  }

  .rowThree{
    justify-content: left;
    padding: 20px;
  }

  .subscribeButton {
    width: 100%;
  }
}


</style>
