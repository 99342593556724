<template>
  <div class="outerDiv">
    <div class="headerWrapper">
      <div class="headerContent">
        <h2 class="header">Kontakt</h2>
      </div>
    </div>
    <div class="flex-container-small">
      <span style="text-align: center; display: block">Nutzen Sie zur Kontaktaufnahme gerne unser Kontaktformular. Wir melden uns schnellstmöglich bei Ihnen. </span>
    </div>
    <div class="contact-form-margin">
      <contact-form  ref="contactName" @contact-form-submitted="this.contactFormSubmitted" :validateInput="this.validateContactForm"></contact-form>
    </div>
  </div>
</template>

<script>

// lit components
import "/src/components/lit-element/contact-form/contact-form";

// images
import {HttpsRequests} from "../mixins/https-requests";
import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";

export default {
  name: "ContactPage",
  mixins: [SweetAlerts],
  data(){
    return{
      validateContactForm: false,
      lastName: "",
      firstName: "",
      email: "",
      message: "",
      dataPrivacyAccepted: false
    }
  },
  methods:{
    isEmailValid(email){
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    isInputValid(){
      return this.lastName !== "" && this.firstName !== "" && this.email !== "" && this.isEmailValid(this.email) && this.message !== "" && this.dataPrivacyAccepted !== "";
    },

    contactFormSubmitted(event){

      const data = event.detail.data;

      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.message = data.message;
      this.dataPrivacyAccepted = data.dataPrivacyAccepted === "on";
      this.email = data.email;

      this.validateContactForm = true;

      if (this.isInputValid()){
        this.postContactForm().then(()=>{
          this.showSuccessMessage("Kontakformular","Ihre Kontaktaufnahme wurde erfolgreich übermittelt. " +
              "Wir melden uns innerhalb von 24 Stunden bei Ihnen.")

          this.lastName = "";
          this.firstName = "";
          this.email = "";
          this.message = "";
          this.dataPrivacyAccepted = false;

          this.validateContactForm = false;

          this.$refs.contactName.resetForm();
        });
      } else {
         this.showErrorMessage("Fehler Kontaktformular", "Bitte prüfen Sie Ihre Eingabe und korrigieren / vervollständigen Sie die rot " +
              "markierten Felder.");
        }
    },

    postContactForm(){
      return new Promise(resolve => {
        const payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          message: this.message
        }

        HttpsRequests.postRequest("submitContactForm", payload).then(() => {
          resolve();
        })
      })
    }
  }
}
</script>

<style scoped>
  .outerDiv{
    padding-top: 100px;
  }

  .headerContent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  .margin-bottom{
    margin-bottom: 10px;
  }

  .address-container{
    text-align: left;
  }

  .header{
    font-size: 40px;
    font-weight: 500;
  }

  .flex-container{
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    margin: 60px auto 80px auto;
    padding: 0 20px;
    align-items: center;
  }

  .flex-container-small{
    display: block;
    justify-content: space-between;
    max-width: 1100px;
    margin: 30px auto 40px auto;
    padding: 0 10px;
  }

  .address-wrapper{
    display: flex;
    flex-direction: column;
  }

  .img-container{
    max-width: 430px;
  }

  .adressHeader{
    font-size: larger;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .contact-form-margin{
    margin-bottom: 100px;
  }

  .img-container{
    margin-left: 20px;
  }

  @media only screen and (max-width: 720px){
    .flex-container, .flex-container-small{
      flex-direction: column;
    }

    .img-container{
      margin-top: 20px;
      max-width: none;
      margin-left: 0;
    }

    .address-container{
      margin-bottom: 80px;
    }
  }
</style>
