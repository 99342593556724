<template>
  <div>
    <div class="top-menu">
      <nav class="top-menu-wrapper">
        <div class="top-menu-logo">
          <div style="display: flex; flex-direction: row;">
            <img :src="rhmLogo" alt="This image shows the logo of the company DMD">
            <div @click="this.navigateToTop" style="text-align: left; margin-left: 10px; font-family: 'Helvetica'; font-weight: 600;">
              <span style="display: inline-block;">Dosen Maschinenbau</span>
              <span>Dienstleistung</span>
            </div>
          </div>
        </div>
        <div>
          <router-link to="/#start">Start</router-link>
          <router-link to="/#services">Dienstleistungen</router-link>
          <router-link to="/#company">Unternehmen</router-link>
          <router-link to="/#contact">Kontakt</router-link>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
// images
import rhmLogo from "/src/assets/logos/logo-dosen-maschinenbau.png";

export default {
  name: 'NavBar',
  data() {
    return {
      rhmLogo: rhmLogo,
    }
  },

  methods:{
    navigateToTop(){

      window.scrollTo(0,0);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.top-menu{
  padding: 0 20px;
  background-color: var(--bright);
  color: black;
  font-size: 14px;
  position: fixed;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  font-family: var(--secondary-font);
  font-weight: 400;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 20px 0;
}

.top-menu-wrapper{
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1110px;
  height: 70px;
  margin: 0 auto;
}

.top-menu-logo{
  max-width: 110px;
}

.top-menu-logo:hover{
  cursor: pointer;
  opacity: 0.8;
}

.top-menu-logo img {
  width: 100%;
  height: 100%;
  max-height: 50px;
}

a{
  padding: 15px;
  color: var(--grey);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
}

a:hover{
  color: var(--darkgrey);
}

</style>
