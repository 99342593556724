import {css} from "lit-element";

export const ContactFormStyles = css`
  
  .content{
    text-align: left;
  }
  
  .outerDiv{
    max-width: 1100px;
    margin: auto;
    padding: 0 20px;
  }

  .nameWrapper{
    display: flex;
  }
  
  .border-red{
    border:1px solid indianred;
  }

  input[type=text], input[type=email]{
    box-sizing: border-box;
    width: 100%;
  }
  
  input{
    height: 40px;
    padding: 10px;
    outline: none;
    border-radius: 2px;
    border: 1px solid var(--secondary-color);
  }

  input:focus {
    background-color: #f4f4f4;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
  }
  
  .innerWrapper{
    width: 100%;
  }
  
  .margin-right{
    margin-right: 20px;
  }

  .margin-left{
    margin-left: 20px;
  }
  
  .margin-bottom{
    margin-bottom: 20px;
  }
  
  .checkbox-wrapper{
    display: flex;
    align-items: center;
  }


`