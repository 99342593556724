import {css} from "lit";

export const customerReviewsStyles = css`
    
  .outerDiv{
    max-width: 720px;
    margin: auto;
  }
  
  .contentWrapper{
    margin: auto;
    column-count: 3;
    text-align: center;
  }

  .content{
    max-width: 300px;
  }
  
  .customerImageWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .customerImage{
    max-width: 150px;
    border-radius: 2px;
  }
  
.innerWrapper{
  text-align: left;
}
  
  .rowTwo{
    display: flex;
    justify-content: space-around;
  }

  .customerNameWrapper{
    margin-bottom: 10px;
  }
  
  .customerName{
    font-weight: 500;
  }
  
  .customerCompany{
    font-weight: lighter;
  }

  @media only screen and (max-width: 1050px) {

    .content {
      max-width: none;
      margin: 0 0 20px 0;
    }


      .contentWrapper {
        margin-left: 20px;
        margin-right: 20px;
        overflow-x:auto;
        column-count: 3;
      }

      .customerNameWrapper {
        margin-top: 10px;
      }

      .customerTextWrapper, .customerText {
        min-height: auto;
      }
  }

  @media only screen and (max-width: 500px) {
    .contentWrapper {
      column-count: 2;
    }
  }

  
  
  
  
  
`