import {createRouter, createWebHistory} from "vue-router";
import Home from "/src/views/Home";

const routes = [
    //Fallback route (404) -> if page is not found route to homepage
    {
        path: '/:pathMatch(.*)',
        component: Home
    },
    {
        path: "/",
        component: Home
        },
    {
        path: "",
        component: Home
    },
    {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "about" */ "/src/views/Home")
    },
    {
        path: "/data-privacy",
        component: () => import(/* webpackChunkName: "data-privacy" */"/src/views/small/DataPrivacy")
    },
    {
        path: "/legal-notice",
        component: () => import(/* webpackChunkName: "legal-notice" */"/src/views/small/LegalNotice")
    },
]

//When using createWebHistory(), the URL will
// look "normal," https://example.com/user/id (without hashtag)
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash };
        }
        return { x: 0, y: 0 };
    }
})

export default router
