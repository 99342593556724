import {html} from "lit";
import {LitElement} from "lit";
import {LandingHeaderStyles} from "./landing-header-styles";
import {LandingHeaderStrings} from "./landing-header-strings";

export class LandingHeader extends LitElement{

    constructor() {
        super();
        this.landingHeader = "";
        this.landingDescription = "";
        this.landingImg = null;
    }

    static get properties(){
        return {
            landingHeader:{Type:String},
            landingDescription:{Type:String},
            landingImg:{}
        }
    }

    static get styles(){
        return LandingHeaderStyles;
    }

    goToAboutUs(){
        const event = new CustomEvent("about-us-selected");
        this.dispatchEvent(event);
    }

    render(){
        return html`
                <div class="outerDiv">
                    <div class="slider slider-bg" style="background-image: url(${this.landingImg});">
                        <div class="slider-text-wrapper">
                            <h1 style="position: absolute; opacity: 0;">RHM Service</h1>
                            <div class="slider-header-text">
                                ${this.landingHeader}
                            </div>
                            <div class="slider-text">
                                ${this.landingDescription}
                            </div>
                            <div class="slider-button-wrapper">
                                <button class="slider-button" @click="${()=>this.goToAboutUs()}">${LandingHeaderStrings.sliderShowMore()}</button>
                            </div>
                        </div>
                    </div>
                </div>
        `
    }
}


customElements.define("landing-header", LandingHeader);
