import {css} from "lit"

export const HoverCardsStyles = css`

  a {
    color: black;
    text-decoration: none;
  }

  .cards {
    background-color: white;
    width: 340px;
    max-width: 340px;
    min-height: 350px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px 0px;
    transition: all 0.2s ease-in-out 0s;
    position: relative;
    z-index: 1;
  }

  .cards:hover {
    transform: scale(1.1);
  }


  .outerDiv {
    max-width: 1100px;
    margin: auto;
    padding-bottom: 100px;
    padding-top: 10px;
  }

  .imgWrapper {
    background-color: white;
    padding: 20px;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    max-height: 152px;
    margin: auto;
    width: auto;
    max-width: 150px;
    text-align: center;
    display: flex;
  }

  .header {
    font-weight: 500;
    margin-top: 10px;
    color: black;
  }

  .headerWrapper {
    text-align: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 7px;
  }

  .contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
  }

  .showMore {
    color: var(--primary-color);
    font-family: var(--secondary-font);
    font-size: 16px;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .description {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    color: grey;
    max-height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 0 20px;
  }

  .arrow:hover {
    cursor: pointer;
  }

  .progressWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .innerWrapper {
    width: 400px;
    display: flex;
    justify-content: space-around;
  }

  .bar {
    width: 120px;
    height: 5px;
    border: 1px solid lightgray;
    border-radius: 10px;
    background: linear-gradient(to right, var(--primary-color) 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
  }

  .bar:hover {
    cursor: pointer;
  }

  .active{
    animation-duration: 10s;
    animation-name: progress;
  }

  .label {
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-size: 12px;
    padding: 5px;
    background-color: #012241;
    color: white;
    border-radius: 2px;
  }


  @keyframes progress {
    from {
      background-position: right bottom;
    }

    to {
      background-position: left bottom;
    }
  }

  @media only screen and (max-width: 420px) {
    .contentWrapper{
      gap: 0;
      margin-bottom: 40px;
    }

    .innerWrapper{
      width: auto;
    }

    .cards{
      margin-bottom: 20px;
    }

  }
`
