export class PrimaryTextStrings{

    static primaryTextDescription() {
        return "Unsere langjährige Industrieerfahrung und die Bereitschaft, die berühmte extra Meile zu laufen, ermöglichen es uns" +
            " Sie bei individuellen Service-Projekten bei vielseitigen Aufgabenstellungen zu unterstützen."
    }

    static primaryTextHeader(){
        return "Immer das passende Servicekonzept für Ihre Aufgabe";
    }

}