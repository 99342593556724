import {html} from "lit";
import {LitElement} from "lit";
import {ContactFormStrings} from "./contact-form-strings";
import {ContactFormStyles} from "./contact-form-styles";

// keep it.
// eslint-disable-next-line no-unused-vars
import {Button} from "/src/components/lit-element/button/button";

export class ContactForm extends LitElement{
    constructor() {

        super();

        this.firstName = "";

        this.lastName = "";

        this.email = "";

        this.message = "";

        this.dataPrivacyAccepted = false;

        this.validateInput = false;
    }

    static get styles(){
        return ContactFormStyles;
    }

    static get properties(){
        return{
            lastName:{Type:String},
            firstName:{Type:String},
            email:{Type:String},
            message:{Type:String},
            dataPrivacyAccepted:{Type:String},
            validateInput:{Type:Boolean}
        }
    }

    contactFormSubmitted(){
        const data = {
            lastName: this.lastName,
            firstName: this.firstName,
            email: this.email,
            message: this.message,
            dataPrivacyAccepted: this.dataPrivacyAccepted
        }

        console.log(data);

        const event = new CustomEvent("contact-form-submitted", {
            detail: {data: data}
        });

        this.dispatchEvent(event);
    }

    isEmailValid(email){
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    resetForm(){
        this.lastName = "";
        this.firstName = "";
        this.email = "";
        this.message = "";
        this.dataPrivacyAccepted = false;
    }

    render(){
        return html`
            <div class="outerDiv">
                <div class="content">
                    <div class="margin-bottom nameWrapper">
                        <div class="margin-right innerWrapper">
                            <label for="firstName">${ContactFormStrings.firstName()}</label>
                            <input .value="${this.firstName}" @change="${(event)=> this.firstName = event.target.value}" 
                                   type="text" id="firstName" required class="nameField ${this.validateInput? this.firstName === "" ? "border-red":"" :""}">
                        </div>
                        <div class="margin-left innerWrapper">
                            <label for="lastName">${ContactFormStrings.lastName()}</label>
                            <input .value="${this.lastName}"  @change="${(event)=> this.lastName = event.target.value}" type="text" id="lastName" 
                                    required class="nameField ${this.validateInput? this.lastName === "" ? "border-red":"" :""}">
                        </div> 
                    </div>
                    <div class="margin-bottom innerWrapper">
                        <label for="email">${ContactFormStrings.email()}</label>
                        <input .value="${this.email}" @change="${(event)=> this.email = event.target.value}" type="email" id="email" required 
                               class="emailField ${this.validateInput? this.email === "" || !this.isEmailValid(this.email) ? "border-red":"" :""}">
                    </div>
                    <div class="margin-bottom innerWrapper">
                        <label for="message">${ContactFormStrings.message()}</label>
                        <input .value="${this.message}" @change="${(event)=> this.message = event.target.value}" type="text" id="message" required 
                               class="messageField ${this.validateInput? this.message === "" ? "border-red":"" :""}">
                    </div>
                    <div style="padding: 5px; box-sizing: border-box; margin-bottom: 20px" class="checkbox-wrapper innerWrapper ${this.validateInput? this.dataPrivacyAccepted !== "on" ? "border-red":"" :""}">
                        <label for="data">${ContactFormStrings.data()}</label>
                        <input style="height:20px; width: 20px" @change="${(event)=> this.dataPrivacyAccepted = event.target.value}" .checked=${this.dataPrivacyAccepted} 
                               type="checkbox" id="data" required>
                    </div>
                    <single-button @button-clicked="${()=>this.contactFormSubmitted()}" .btnText=${ContactFormStrings.send()} ></single-button>
                </div>
            </div>
        `
    }
}

customElements.define("contact-form", ContactForm)
