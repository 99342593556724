import {html} from "lit";
import {LitElement} from "lit";
import {customerReviewsStyles} from "./customer-reviews-styles";


export class CustomerReviews extends LitElement{
    constructor() {
        super();
        this.srcArray = [];
    }

    static get properties(){
        return {
            srcArray: {Type: Array}
        }
    }

    static get styles(){
        return customerReviewsStyles;
    }

    render() {
        return html`
            <div class="outerDiv">
                <div class="contentWrapper">
                    ${this.srcArray.map((entry)=>html`
                        <div class="content">
                            <div class="innerWrapper">
                                <div class="customerImageWrapper">
                                    <img class="customerImage" src="${entry.imgSrc}" alt="${entry.imgAlt}">
                                </div>
                                <div class="customerTextWrapper">
                                    <div class="customerText">${entry.companyDescription}</div>
                                </div>
                                <div class="customerNameWrapper">
                                    <div class="customerName">${entry.companyName}</div>
                                </div>
                            </div>
                        </div>
                    `)}
                </div>
            </div>
        `
    }
}

customElements.define("customer-reviews", CustomerReviews)